<template>
  <div>
    <section id="content">
      <div class="section nobg nomargin">
        <div class="container">
          <h3>Terms of Use</h3>
          <p>
            This terms of service contains an arbitration agreement, which will,
            with limited exception, require you to submit claims you have
            against us to binding and final arbitration. Under the arbitration
            agreement, (1) you will only be permitted to pursue claims against
            company on an individual basis, not as a plaintiff or class member
            in any class or representative action or proceeding, and (2) you
            will only be permitted to seek relief (including monetary,
            injunctive, and declaratory relief) on an individual basis.
          </p>
          <h4>1.0 PRODUCT</h4>
          <p>
            M-Changa is a mobile fundraising product developed, operated and
            owned by Mobi Changa Limited Kenya, a limited liability company
            incorporated in Kenya and a neutral content provider of mobile
            alerts, Fundraising applications and related services . The Company
            is not a financial institution and does not accept deposits.
            M-Changa, a Mobile based and Online application, enables users to
            fundraise easily and efficiently in a transparent manner.
          </p>
          <p>
            Please read this document carefully before accessing or using
            M-Changa.
          </p>
          <h4>2.0 DEFINITIONS</h4>
          <p>
            In this Agreement, the following terms and expressions shall, unless
            the context otherwise requires, have the following meanings:
          </p>
          <p>
            “Account” means a Customer's virtual M-Changa Account being the
            record maintained by the Company of the amount of E-Value held by
            the Customer and represented by an equivalent amount of cash less
            fees and charges held by the Company's PAYBILL Account on the
            Customer's behalf. “Agreement” means these Terms of Use or its
            amendments thereof from time to time.
          </p>
          <p>
            “Balance” means the amount of E-Value from time to time standing to
            the credit of your Account. “Customer” means you and every other
            person who is using the M-Changa service.
          </p>
          <p>
            “E-Value” means the electronic value recorded in a Customer's
            Account, such electronic value representing that Customer's
            entitlement to an equivalent amount of the cash amount held in the
            PAYBILL Account.
          </p>
          <p>
            “Fees” means the Transaction Fees and other charges payable under
            this Agreement for the MChanga Services.
          </p>
          <p>
            “Fundraising” means campaigns for raising money from people or
            contribution and donation of money by people or groups of people for
            various causes or kitties.
          </p>
          <p>
            “Mobile Phone” means your mobile phone handset or mobile device.
          </p>
          <p>
            “MSISDN” means the Mobile Subscriber Integrated Services Digital
            Network Number or the identification number of your SIM card as
            allocated by Safaricom, Airtel Networks, Equitel, or any other
            mobile operators which may have entered into arrangements with us
            for provision of the Service.
          </p>
          <p>
            “Network Service Provider” or “Service Provider” means Safaricom or
            Airtel Networks, Equitel, or any other mobile operator which may
            have entered into arrangements with us for provision of the Service.
          </p>
          <p>
            “Pay bill Account” means where you decide to use your own Pay bill
            account as may be assigned to you by Safaricom Limited or Airtel
            Networks Kenya Limited, Equitel, or another mobile operator. This
            means that you will not use the PAYBILL Account assigned to the
            Company.
          </p>
          <p>
            “Payments” means money or monies paid to your account as a
            contribution or donation towards the Fundraising.
          </p>
          <p>
            “PAYBILL Account” means the account assigned to the Company by
            Safaricom Limited or Airtel Networks Kenya Limited, Equitel, or
            other mobile operator through their mobile money platforms.
            “Product” or “Service” or “Services” means any products and services
            offered by M-Changa, including its website located at
            www.changa.co.ke, software, and mobile applications. “SIM Card”
            means the subscriber identity module which when used with the
            appropriate Mobile equipment or phone enables you to use the MPESA
            or Airtel Money, Equitel, or other mobile money services.
          </p>
          <p>
            “SMS” means a Short Message Service consisting of a text message
            transmitted from one mobile subscriber network to another.
          </p>
          <p>
            “Trust Account” means the commercial bank account maintained by the
            Company into which all Payments are made and held by the Company on
            behalf of Customers. “we” or “us” or “our” or “Company” or
            “M-Changa” means Mobi Changa Limited Kenya. “you” or “your” means
            the Customer.
          </p>
          <p>
            "*483*57#" is the USSD Number to access and operate M-Changa
            Service.
          </p>

          <h4>3.0 TERMS AND CONDITIONS</h4>
          <h5>3.1 USE OF M-CHANGA</h5>
          <p>
            This Terms of Use governs the use by you of M-Changa, the acceptance
            of which constitutes a binding contract between yourself and the
            Company. You acknowledge that your use of the M-Changa Service is at
            free will and you have not been coerced or compelled to use this
            Service howsoever. You agree to abide by these Terms of Use with
            respect to the use of M-Changa and such other products and services
            such as PAYPAL, third-party merchant accounts, Social Network
            Platforms as may from time to time be made available to you by us.
          </p>
          <p>
            You acknowledge that Company may establish general practices and
            limits concerning use of the Service, including without limitation
            the maximum period of time that data or other content will be
            retained by the Service and the maximum storage space that will be
            allotted on Company's servers on your behalf. You agree that Company
            has no responsibility or liability for the deletion or failure to
            store any data or other content maintained or uploaded by the
            Service. You acknowledge that Company reserves the right to
            terminate accounts that are inactive for an extended period of time.
            You further acknowledge that Company reserves the right to change
            these general practices and limits at any time, in its sole
            discretion, with or without notice. If you choose to register for
            the Service, you agree to provide and maintain true, accurate,
            current and complete information about yourself as prompted by the
            USSD code *483*57# or via the web registration link. You are
            responsible for maintaining the confidentiality of your password and
            Account, including your MPESA or Airtel Money or such other mobile
            money password or secret system identification number. You are fully
            responsible for any and all activities that occur under such
            passwords or Account. You agree to (a) immediately notify Company of
            any unauthorized use of your passwords or Account or any other
            breach of security, and (b) ensure that you exit from your Account
            at the end of each session when accessing the Service. Company will
            not be liable for any loss or damage arising from your failure to
            comply with this Section 2.0 or any other applicable terms contained
            herein. We shall not be held liable for the unauthorized use of your
            Account where you have failed to ensure the secrecy of any such
            passwords or secret system identification number. Further, we shall
            not be held liable for the withdrawal of money from the Account
            without the necessary approvals where you have failed to utilize the
            multiple treasurers' security feature in the Product.
          </p>
          <p>
            Upon completion of the process of subscribing to or accessing the
            M-Changa service on your Mobile Phone you are given a limited, non-
            exclusive, non transferable, non assignable, non- sub licensable and
            revocable license to use the Service on your Mobile Phone. You shall
            use the Service on your Mobile Phone subject to the Terms of Service
            stated herein at your own risk and agree not to blame us for any
            malfunction(s) related to your Mobile Phone hardware or your Network
            Service Provider. To the extent you access the Service through a
            Mobile Phone, your wireless service carrier's standard charges, data
            rates and other fees may apply. In addition, downloading,
            installing, or using certain mobile services may be prohibited or
            restricted by your carrier, and not all mobile Services may work
            with all carriers or devices. By using the Service through the
            Mobile Phone, you agree that we may communicate with you regarding
            Company and other entities by SMS, MMS, text message or other
            electronic means to your Mobile Phone and that certain information
            about your usage of the Services may be communicated to us. In the
            event you change or deactivate your mobile telephone number, you
            agree to promptly update your Account information to ensure that
            your messages are not sent to the person that acquires your old
            number.
          </p>
          <p>
            While we will try to ensure that you are able to use the Product
            comfortably, we do not guarantee that the Product will be available
            at all times and we will not be held responsible or liable in any
            way for any loss whatsoever or howsoever arising as a consequence of
            any non-availability of the Product. By accepting these Terms of
            Service you acknowledge that the M-Changa Services are not fault
            free and that there are certain circumstances and factors including
            (but not limited to) acts of God, mobile operator issues or problems
            such as maintenance or unavailability of network which may interfere
            adversely with the quality and provision of the Services.
          </p>
          <p>
            The confidentiality of your communications while using the Service
            is not guaranteed. You are advised that for reasons beyond our
            control, there is a risk that your communications may be unlawfully
            intercepted or accessed by individuals other than the intended
            recipient. We cannot and will not accept any liability for any loss,
            injury or damage whether direct or consequential arising out of any
            such compromise of confidentiality. In addition to all of the above,
            you further understand and accept the following basic terms and
            conditions on use of M-Changa:
          </p>
          <ol style="list-style-type: upper-roman">
            <li>Setting up a fundraiser on M-Changa is free.</li>
            <li>
              You can only manage one active fundraiser with one MSIDN or SIM
              Line on M-Changa at any given time, however you can contribute to
              an unlimited number of fundraisers started by other people on
              M-Changa.
            </li>
            <li>
              You can withdraw your funds at any time provided your Balance is
              at least KES 250/-.
            </li>
            <li>
              The latest normal Network Service Provider tariffs apply for all
              withdrawals and contributions.
            </li>
            <li>
              A fundraiser may have a maximum of three (3) Treasurers who
              approve withdrawals.
            </li>
            <li>
              Other applicable Fees for use of this Service are described
              elsewhere in our website.
            </li>
          </ol>

          <h5>3.2 CUSTODY OF MONEY</h5>
          <p>
            The Company hereby declares that it holds custody of all Payments
            and other monies received into the PAYBILL, TILL, Paypal, Stripe,
            Airtel Money, T-Kash and Equitel channels with respect to the
            Payments credited to your Account (the “Customer Balances”) for you
            and for your benefit such that you shall be beneficially entitled to
            all those Customer Balances equivalent to the Balance standing to
            the credit of your Account. You agree that we may treat the records
            of our system as conclusive evidence of the amount of E-Value at any
            time standing to the credit of your Account and we are not bound to
            make any independent investigation of your beneficial entitlement to
            the Customer Balances. You further acknowledge that, in relation to
            any payment to you in respect of your entitlement to Customer
            Balances, we may act on instructions given by you using your System
            Identification Number – which is by default the MSISDN or SIM line
            number that is used to register the Fundraiser, or instructions
            purported to be given by you using your System Identification
            Number, even if they are actually given by a third party.
          </p>
          <p>
            You acknowledge that we do not invest or generate money from the
            monies we hold on your behalf in any way whatsoever and shall have
            no obligation to invest the Customer Balances other than by way of
            holding the same in the Customer Balances Account. You are aware
            that we receive this money though the PAYBILL, TILL, Paypal, Stripe,
            Airtel Money, T-Kash and Equitel merchant channels and hold it in
            the Customer Balances Account on your behalf and that we make our
            money through the Fees we charge you for the product. You further
            acknowledge that, to the extent that any interest may accrue on the
            Customer Balances, you shall have no beneficial entitlement to such
            interest and we shall be entitled to retain such interest for our
            own use, whether to defray our own costs and expenses and those of
            operating our system or to pay the same to such charitable cause(s)
            as we shall in our absolute discretion determine, or otherwise.
          </p>
          <p>
            Contributions or donations made to the M-Changa Account do not
            constitute a deposit and as stated hereinbefore we are not a
            financial institution. You accept that the money in your Account or
            held on your behalf is therefore not insured and hold us harmless
            against each and every risk that may occur as a result of the said
            lack of insurance.
          </p>
          <p>
            Notwithstanding the foregoing or any other provision(s) in this
            Agreement, you acknowledge that where you decide to use your own Pay
            bill Account, in addition to the relevant provisions contained
            elsewhere in this Agreement, the following terms and conditions
            shall apply to you:
          </p>

          <ol style="list-style-type: lower-alpha">
            <li>
              We shall, and you hereby authorize us to, integrate your Pay bill
              Account number with our system so that all the messages that have
              payment information reflect this Pay bill number;
            </li>
            <li>
              We do not have custody, control, access or receipt of payments in
              to your own Pay bill Account. You acknowledge that you receive the
              payments in to your Pay bill Account and have custody, control and
              access to the Pay bill Account, being the owner of the Pay bill
              Account.
            </li>
            <li>
              You hereby indemnify and hold us harmless against any and all
              losses, costs, expenses and/or claims relating to the custody,
              control, access or receipt of payments into your own Pay bill
              Account and shall by so doing take full responsibility and
              liability for such losses, costs, expenses and/or claims.
            </li>
            <li>
              You acknowledge and understand that we only reflect or show (for
              your convenience) details of the payments made in to your Pay bill
              Account.
            </li>
            <li>
              You agree to keep sufficient money in your M-Changa Account
              (“credit”) to be utilized against any Fees payable to us. This
              credit will enable us to recover our Fees since we do not have
              control of money in your Pay bill Account. You may not effect any
              M-Changa transactions if you do not have sufficient credit in your
              Account to meet all applicable Fees in respect thereof.
            </li>
          </ol>
          <h5>3.3 WITHDRAWAL RIGHTS</h5>
          <p>
            In addition to the basic terms and conditions set out under 4.1
            hereinabove and elsewhere in this Agreement in relation to
            withdraws, you note and understand the following:
          </p>
          <ol style="list-style-type: lower-alpha">
            <li>
              We shall strive to effect your withdrawal request within one (1)
              hour to four (4) hours of the request barring factors beyond our
              control;
            </li>

            <li>
              Withdrawal must be made from the SIM card that started the
              fundraiser and any deviation from this condition would require our
              investigation; M-Changa Terms of Use 6
            </li>

            <li>
              In the event your Mobile Phone and/or SIM card is lost, you
              acknowledge that you shall, and it is your responsibility to,
              obtain a replacement of your Mobile Phone and/or SIM card from
              your mobile operator for you to effect a withdrawal;
            </li>

            <li>
              In the event you are incapacitated to the extent of inability to
              withdraw from your Mobile Phone, you agree that:
              <ol style="list-style-type: upper-roman">
                <li>
                  You can personally appear at M-Changa offices with
                  satisfactory evidence of your identity as proof that you
                  started the fundraiser as well as a medical form from a
                  competent medical practitioner to prove that you are
                  incapacitated to the extent of inability to withdraw from your
                  Mobile Phone.
                </li>
                <li>
                  If there were treasurers in the fundraiser we would require
                  them to confirm the said incapacitation
                </li>
              </ol>
            </li>

            <li>
              In the event that the Customer is deceased, the withdrawal from
              their Account can be effected upon the request of the next of kin
              or the legal representative (“Claimant”). The Claimant must
              produce the following documents to our satisfaction before we can
              effect withdrawal of money from the respective Account:
              <ol style="list-style-type: upper-roman">
                <li>
                  The Claimant's original national identification card or
                  passport;
                </li>
                <li>The deceased person's death certificate;</li>
                <li>
                  A Statutory declaration (Affidavit), being a document
                  witnessed by a commissioner for oaths stating certain facts
                  including the Claimant's relation to the deceased; and
                </li>
                <li>
                  A letter from provincial administration, being a letter from
                  the chief, DC or PC confirming certain details about the
                  deceased person and his beneficiaries. The confirmation will
                  include the full names of the deceased, the area where the
                  deceased was from and ALL the persons who are beneficiaries of
                  the estate of the deceased; or
                </li>
                <li>
                  Confirmation of Grant of Letters of Administration where the
                  deceased person died intestate or Confirmation of Grant of
                  Probate where the deceased person died testate pursuant and
                  subject to the applicable succession laws.
                </li>
              </ol>
            </li>
          </ol>
          <h5>3.4 PROPRIETARY RIGHTS</h5>
          <p>
            The technology and software underlying the Service or distributed in
            connection therewith, including any content or features contained
            therein, are the property of Company, our affiliates and our
            partners (the “Software”). Except as expressly authorized by the
            Company, you agree not to modify, copy, frame, scrape, rent, lease,
            loan, sell, assign, sublicense, or otherwise distribute, create
            derivative works of, reverse engineer, reverse assemble or otherwise
            attempt to discover any source code in the Software. In connection
            with your use of the Service you will not engage in or use any data
            mining, robots, scraping or similar data gathering or extraction
            methods. If you are blocked by the Company from accessing the
            Service (including by blocking your IP address), you agree not to
            implement any measures to circumvent such blocking (e.g., by masking
            your IP address or using a proxy IP address). Any use of the Service
            or the Service content other than as specifically authorized herein
            is strictly prohibited. Any rights not expressly granted herein are
            reserved by the Company.
          </p>
          <p>
            The Company name and logos are trademarks and service marks of the
            Company (collectively the “Company Trademarks”). Other company,
            product, and service names and logos used and displayed via the
            Service may be trademarks or service marks of their respective
            owners who may or may not endorse or be affiliated with or connected
            to the Company. Nothing in this Terms of Use or the Service should
            be construed as granting, by implication, estoppel, or otherwise,
            any license or right to use any of Company Trademarks displayed on
            the Service, without our prior written permission in each instance.
            All goodwill generated from the use of Company Trademarks will inure
            to our exclusive benefit.
          </p>
          <p>
            Under no circumstances will the Company be liable in any way for any
            content or materials of any third parties (including users),
            including, but not limited to, for any errors or omissions in any
            content, or for any loss or damage of any kind incurred as a result
            of the use of any such content. You acknowledge that Company does
            not pre-screen content, but that Company and its designees will have
            the right (but not the obligation) in their sole discretion to
            refuse or remove any content that is available via the Service.
            Without limiting the foregoing, the Company and its designees will
            have the right to remove any content that violates these Terms of
            Use or is deemed by the Company, in its sole discretion, to be
            otherwise objectionable. You agree that you must evaluate, and bear
            all risks associated with, the use of any content, including any
            reliance on the accuracy, completeness, or usefulness of such
            content.
          </p>
          <p>
            With respect to the content or other materials you upload through
            the Service or share with other users or recipients (collectively,
            “User Content”), you represent and warrant that you own all right,
            title and interest in and to such User Content, including, without
            limitation, all copyrights and rights of publicity contained
            therein. By uploading any User Content you hereby grant and will
            grant Company and its affiliated companies a nonexclusive,
            worldwide, royalty free, fully paid up, transferable, sublicensable,
            perpetual, irrevocable license to copy, display, upload, perform,
            distribute, store, modify and otherwise use your User Content in
            connection with the operation of the Service or the promotion,
            advertising or marketing thereof, in any form, medium or technology
            now known or later developed.
          </p>
          <p>
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback or other information about the Service
            (“Submissions”), provided by you to the Company are non-confidential
            and Company will be entitled to the unrestricted use and
            dissemination of these Submissions for any purpose, commercial or
            otherwise, without acknowledgment or compensation to you.
          </p>
          <p>
            You acknowledge and agree that the Company may preserve content and
            may also disclose content if required to do so by law or in the good
            faith belief that such preservation or disclosure is reasonably
            necessary to: (a) comply with legal process, applicable laws or
            government requests; (b) enforce these Terms of Use; (c) respond to
            claims that any content violates the rights of third parties; or (d)
            protect the rights, property, or personal safety of the Company, its
            users and the public. You understand that the technical processing
            and transmission of the Service, including your content, may involve
            (a) transmissions over various networks; and (b) changes to conform
            and adapt to technical requirements of connecting networks or
            devices.
          </p>
          <p>Copyright Complaints:</p>
          <p>
            Company respects the intellectual property of others, and we ask our
            users to do the same. If you believe that your work has been copied
            in a way that constitutes copyright infringement, or that your
            intellectual property rights have been otherwise violated, you
            should notify Company of your infringement claim in accordance with
            the procedure set forth below.
          </p>
          <p>
            Company will process and investigate notices of alleged infringement
            and will take appropriate actions under the Digital Millennium
            Copyright Act (“DMCA”) and other applicable intellectual property
            laws with respect to any alleged or actual infringement. A
            notification of claimed copyright infringement should be emailed to
            Company's Copyright Agent at {{ this.supportEmail }} (Subject line:
            "DMCA Takedown Request"). You may also contact us by mail at: Mobi
            Changa Limited, P.O. Box 55237-00200 GPO Nairobi, KENYA
          </p>
          <p>
            To be effective, the notification must be in writing and contain the
            following information:
          </p>
          <ul style="list-style-type: upper-roman">
            <li>
              an electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright or other intellectual
              property interest;
            </li>
            <li>
              a description of the copyrighted work or other intellectual
              property that you claim has been infringed;
            </li>
            <li>
              a description of where the material that you claim is infringing
              is located on the Service, with enough detail that we may find it
              on the Service;
            </li>
            <li>your address, telephone number, and email address;</li>
            <li>
              a statement by you that you have a good faith belief that the
              disputed use is not authorized by the copyright or intellectual
              property owner, its agent, or the law;
            </li>
            <li>
              a statement by you, made under penalty of perjury, that the above
              information in your Notice is accurate and that you are the
              copyright or intellectual property owner or authorized to act on
              the copyright or intellectual property owner's behalf.
              Counter-Notice: If you believe that your User Content that was
              removed (or to which access was disabled) is not infringing, or
              that you have the authorization from the copyright owner, the
              copyright owner's agent, or pursuant to the law, to upload and use
              the content in your User Content, you may send a written
              counternotice containing the following information to the
              Copyright Agent:
            </li>

            <li>your physical or electronic signature;</li>
            <li>
              identification of the content that has been removed or to which
              access has been disabled and the location at which the content
              appeared before it was removed or disabled;
            </li>
            <li>
              a statement that you have a good faith belief that the content was
              removed or disabled as a result of mistake or a misidentification
              of the content; and
            </li>
            <li>
              your name, address, telephone number, and email address, a
              statement that you consent to the jurisdiction of the federal
              court located within Kenya and a statement that you will accept
              service of process from the person who provided notification of
              the alleged infringement.
            </li>
          </ul>
          <p>
            If a counter-notice is received by the Copyright Agent, Company will
            send a copy of the counter-notice to the original complaining party
            informing that person that it may replace the removed content or
            cease disabling it in 10 business days. Unless the copyright owner
            files an action seeking a court order against the content provider,
            member or user, the removed content may be replaced, or access to it
            restored, in 10 to 14 business days or more after receipt of the
            counter-notice, at our sole discretion.
          </p>
          <p>
            Repeat Infringer Policy: In accordance with the DMCA and other
            applicable law, Company has adopted a policy of terminating, in
            appropriate circumstances and at Company's sole discretion, users
            who are deemed to be repeat infringers. Company may also at its sole
            discretion limit access to the Service and/or terminate the
            memberships of any users who infringe any intellectual property
            rights of others, whether or not there is any repeat infringement.
          </p>
          <h5>(3.5) APPLE-ENABLED SOFTWARE APPLICATIONS</h5>
          <p>
            Company offers Software applications that are intended to be
            operated in connection with products made commercially available by
            Apple Inc. (“Apple”), among other platforms. With respect to
            Software that is made available for your use in connection with an
            Apple-branded product (such Software, “Apple-Enabled Software”), in
            addition to the other terms and conditions set forth in these Terms
            of Service, the following terms and conditions apply:
          </p>
          <p>
            Company and you acknowledge that these Terms of Service are
            concluded between Company and you only, and not with Apple, and that
            as between Company and Apple, Company, not Apple, is solely
            responsible for the Apple-Enabled Software and the content thereof.
          </p>
          <p>
            You may not use the Apple-Enabled Software in any manner that is in
            violation of or inconsistent with the Usage Rules set forth for
            Apple-Enabled Software in, or otherwise be in conflict with, the App
            Store Terms of Service.
          </p>
          <p>
            Your license to use the Apple-Enabled Software is limited to a
            non-transferable license to use the AppleEnabled Software on an iOS
            Product that you own or control, as permitted by the Usage Rules set
            forth in the App Store Terms of Service.
          </p>
          <p>
            Apple has no obligation whatsoever to provide any maintenance or
            support services with respect to the Apple-Enabled Software.
          </p>
          <p>
            Apple is not responsible for any product warranties, whether express
            or implied by law. In the event of any failure of the Apple-Enabled
            Software to conform to any applicable warranty, you may notify
            Apple, and Apple will refund the purchase price for the
            Apple-Enabled Software to you, if any; and, to the maximum extent
            permitted by applicable law, Apple will have no other warranty
            obligation whatsoever with respect to the Apple-Enabled Software, or
            any other claims, losses, liabilities, damages, costs or expenses
            attributable to any failure to conform to any warranty, which will
            be Company's sole responsibility, to the extent it cannot be
            disclaimed under applicable law.
          </p>
          <p>
            Company and you acknowledge that Company, not Apple, is responsible
            for addressing any claims of you or any third party relating to the
            Apple-Enabled Software or your possession and/or use of that
            Apple-Enabled Software, including, but not limited to: (i) product
            liability claims; (ii) any claim that the Apple-Enabled Software
            fails to conform to any applicable legal or regulatory requirement;
            and (iii) claims arising under consumer protection or similar
            legislation.
          </p>
          <p>
            In the event of any third party claim that the Apple-Enabled
            Software or the end-user's possession and use of that Apple-Enabled
            Software infringes that third party's intellectual property rights,
            as between Company and Apple, Company, not Apple, will be solely
            responsible for the investigation, defense, settlement and discharge
            of any such intellectual property infringement claim.
          </p>
          <p>
            You represent and warrant that (i) you are not located in a country
            that is subject to a U.S. Government embargo, or that has been
            designated by the U.S. Government as a “terrorist supporting”
            country; and (ii) you are not listed on any U.S. Government list of
            prohibited or restricted parties.
          </p>
          <p>
            If you have any questions, complaints or claims with respect to the
            Apple-Enabled Software, they should be directed to Company via email
            or mail as follows: admin@changa.co.ke , Mobi Changa Limited, P.O.
            Box 55237-00200 GPO Nairobi, KENYA.
          </p>
          <p>
            Company and you acknowledge and agree that Apple, and Apple's
            subsidiaries, are third party beneficiaries of these Terms of
            Service with respect to the Apple-Enabled Software, and that, upon
            your acceptance of the terms and conditions of these Terms of
            Service, Apple will have the right (and will be deemed to have
            accepted the right) to enforce these Terms of Service against you
            with respect to the Apple-Enabled Software as a third party
            beneficiary thereof.
          </p>

          <h5>(3.6) SOCIAL NETWORKING SERVICES</h5>
          <p>
            You may enable or log in to the Service via various online third
            party services, such as social media and social networking services
            like Facebook or Twitter (“Social Networking Services”). By logging
            in or directly integrating these Social Networking Services into the
            Service, we make your online experiences richer and more
            personalized. To take advantage of this feature and capabilities, we
            may ask you to authenticate, register for or log into Social
            Networking Services on the websites of their respective providers.
            As part of such integration, the Social Networking Services will
            provide us with access to certain information that you have provided
            to such Social Networking Services, and we will use, store and
            disclose such information in accordance with our Privacy Policy. For
            more information about the implications of activating these Social
            Networking Services and Company's use, storage and disclosure of
            information related to you and your use of such services within
            Company (including your friend lists and the like), please see our
            Privacy Policy at www.changa.co.ke . However, please remember that
            the manner in which Social Networking Services use, store and
            disclose your information is governed solely by the policies of such
            third parties, and Company shall have no liability or responsibility
            for the privacy practices or other actions of any third party site
            or service that may be enabled within the Service.
          </p>
          <p>
            In addition, Company is not responsible for the accuracy,
            availability or reliability of any information, content, goods,
            data, opinions, advice or statements made available in connection
            with Social Networking Services. As such, Company is not liable for
            any damage or loss caused or alleged to be caused by or in
            connection with use of or reliance on any such Social Networking
            Services. Company enables these features merely as a convenience and
            the integration or inclusion of such features does not imply an
            endorsement or recommendation.
          </p>
          <h5>3.5 THIRD PARTY WEBSITES</h5>
          <p>
            The Service may provide, or third parties may provide, links or
            other access to other sites and resources on the Internet. The
            Company has no control over such sites and resources and the Company
            is not responsible for and does not endorse such sites and
            resources. You further acknowledge and agree that the Company will
            not be responsible or liable, directly or indirectly, for any damage
            or loss caused or alleged to be caused by or in connection with use
            of or reliance on any content, events, goods or services available
            on or through any such site or resource. Any dealings you have with
            third parties found while using the Service are between you and the
            third party, and you agree that Company is not liable for any loss
            or claim that you may have against any such third party.
          </p>
          <h5>3.6 CHANGES TO THE PRODUCT OR TERMS OF USE</h5>
          The Company reserves the right to:
          <ol style="list-style-type: upper-roman">
            <li>
              Change, add, modify or remove any information and materials on the
              Product in any manner and at any time, and/or;
            </li>
            <li>change or amend the Terms of Use at any time, and/or</li>
            <li>
              suspend, limit, restrict and/or deny access to any aspect or
              component or part of the Product at any time for any reason,
              including, without limitation, for any scheduled or unscheduled
              maintenance, upgrades, improvements or corrections without giving
              any prior intimation to you or any Customer.
            </li>
            <li>Change the Fees charged for this Product from time to time.</li>
          </ol>
          <p>
            You will be notified of any such changes to the Product by an SMS
            notification or by an email notification or by a telephonic call
            notification and you are required to be updated about all such
            changes in terms posted in our website. You authorize us to engage
            with you through the above mentioned notifications and shall not
            hold the Company or any of its subsidiaries, office bearers or any
            director, officer or employee liable for violation of your privacy.
            In addition, your continued use of the Service after any such
            changes to the Product becomes effective constitutes your agreement
            to accept the changes.
          </p>
          <p>
            By keeping or using the service after notification, you are deemed
            to have agreed to the amendment or change. If you do not agree with
            any amendment incorporated in these Terms of Service you may cancel
            the service by informing about the same to us in writing.
          </p>
          <h5>3.7 DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h5>
          <p>
            Your use of the service is at your sole risk. The service is
            provided on an “as is” and “as available” basis. Company expressly
            disclaims all warranties of any kind, whether express, implied or
            statutory, including, but not limited to the implied warranties of
            merchantability, fitness for a particular purpose, title and
            noninfringement. Company makes no warranty that (i) the service will
            meet your requirements, (ii) the service will be uninterrupted,
            timely, secure, or error-free, (iii) the results that may be
            obtained from the use of the service will be accurate or reliable,
            or (iv) the quality of any products, services, information, or other
            material purchased or obtained by you through the service will meet
            your expectations.
          </p>
          <p>
            Without limiting anything stated in this section 3.7, you expressly
            understand and agree that company including its subsidiaries,
            directors, officer or employees of the foregoing will not be liable
            for any direct or indirect, incidental, special, consequential,
            exemplary damages, or damages for loss of profits including but not
            limited to, damages for loss of goodwill, use, data or other
            intangible losses (even if company has been advised of the
            possibility of such damages), whether based on contract, tort,
            negligence, strict liability or otherwise, resulting from: (i) the
            use or the inability to use the service; (ii) the cost of
            procurement of substitute goods and services resulting from any
            goods, data, information or services purchased or obtained or
            messages received or transactions entered into through or from the
            service; (iii) unauthorized access to or alteration of your
            transmissions or data; (iv) statements or conduct of any third party
            on the service; (v) failure to reach the required fundraising sum;
            (vi) failure by target contributors to embrace, use or accept the
            service; (vii) handing over of the relevant mpesa or airtel money or
            other mobile money platform access control/password by you to any
            unauthorized person; (viii) any injury to the character and
            reputation alleged to have been caused by the use of the service; or
            (ix) any other matter relating to the service. In no event will
            company's total liability to you for all damages, losses or causes
            of action exceed one hundred dollars ($100).
          </p>
          <p>
            Some jurisdictions do not allow the exclusion of certain warranties
            or the limitation or exclusion of liability for incidental or
            consequential damages. Accordingly, some of the above limitations
            set forth above may not apply to you. If you are dissatisfied with
            any portion of the service or with these terms of service, your sole
            and exclusive remedy is to discontinue use of the service.
          </p>
          <p>
            In case of loss/theft/destruction of your Mobile Phone, you must
            deactivate the Mobile/SIM card by contacting your service provider.
            The Company does not take any financial liability arising out of any
            subsequent unauthorized use of the Product through any such handset.
          </p>
          <p>
            In case of any technical problem the Company will make its best
            endeavor to resolve the problem within the shortest possible time
            and shall not be responsible for any technical failure or
            malfunctioning of the Product or delays of any kind.
          </p>
          <p>
            All money contributed or donated by use of the M-Changa service
            under these Terms of Service herein will be so procured by Customers
            at their own risk and costs. The Company shall not be liable and/or
            responsible in respect of the source of the money and no guarantee,
            warranty or any indemnity shall be given or deemed to be given by
            the Company in respect thereof.
          </p>

          <h5>3.8 LEGAL RESTRICTIONS</h5>
          <p>
            The M-Changa Product shall not be used by any person or entity in
            any jurisdiction where the direction or use of such a product is
            prohibited or would be contrary to applicable laws, rules or
            regulations of any governmental authority or regulatory
            organization.
          </p>
          <h5>3.9 MISLEADING, OFFENSIVE AND INAPPROPRIATE CONTENT</h5>
          <p>
            We do not endorse any product, service, political party, viewpoint
            or opinion. We reserve the right, without any liability or claim
            howsoever arising, to monitor content on our systems and remove any
            that in our sole opinion and discretion we find misleading,
            offensive, or otherwise inappropriate.
          </p>
          <p>
            You are solely responsible for all transmitted material and/or
            communication which may be classified as defamatory, illegal,
            inappropriate or in breach of any copyright or other intellectual
            property laws and you will indemnify and keep us indemnified against
            all claims made against us in respect thereof and any liability or
            expenses incurred or suffered by us as a result thereof.
          </p>
          <p>
            You must not use M-Changa or any of our Products or service to
            commit any offence.
          </p>

          <h5>
            3.10 SUSPENSION, DISCONNECTION & CLOSURE OF ACCOUNT & SERVICES
          </h5>
          <p>
            We may suspend, bar, restrict or terminate the provision of the
            Services (in part or in whole) and/or close your Account without any
            liability whatsoever and without notification to you in the
            following circumstances:
          </p>
          <ol style="list-style-type: upper-roman">
            <li>
              if you are declared bankrupt or insolvent or otherwise unable to
              pay your debts as and when they fall due or upon the filing of any
              application or proceeding (whether voluntary or involuntary) for
              windingup, bankruptcy, insolvency or relief from creditors;
            </li>
            <li>
              if we are aware or have reason to believe that your Account is
              being used in an unauthorized, unlawful, improper or fraudulent
              manner or for criminal activities (or has been so used
              previously);
            </li>
            <li>
              if any law enforcement or other competent regulatory or
              governmental agency or authority initiates investigation or other
              regulatory or enforcement proceedings against you or for any other
              reason which, in the reasonable judgment of the Company will cast
              doubt on the business reputation of the Company;
            </li>
            <li>
              if you do not comply with any of the conditions, regulations or
              instructions relating to the use of the Services including these
              Terms of Use;
            </li>
            <li>
              if you notify us that the security of your Account is in jeopardy
              or compromised;
            </li>
            <li>upon receipt of your request to close your Account.</li>
          </ol>
          <p>
            Where the Services have been suspended, disconnected or terminated
            or where your Account has been closed, any Balance standing to the
            credit of your Account will be repaid to you in cash upon you
            presenting yourself at our offices and only after production of
            satisfactory evidence of your identity. We will not be responsible
            or liable to you in any way for any direct, indirect, consequential
            or special loss or damages arising from any act or omission by us or
            any third party for whom we are responsible, whether arising in
            contract, or statute, if we close or suspend your Account pursuant
            to this clause.
          </p>

          <h5>3.11 INDEMNITY AND RELEASE</h5>
          <p>
            You agree to release, indemnify, defend and hold harmless the
            Company, its officers, directors, employees, agents and officers
            against all losses, expenses, damages and costs, including
            reasonable legal fees, rights, claims, actions of any kind and
            injury (including death) resulting from your use of the Service, any
            User Content, your connection to the Service, any violation of these
            Terms of Service (including negligent or wrongful conduct) by you or
            any other person accessing the Service. If you are a California
            resident, you waive California Civil Code Section 1542, which says:
            “A general release does not extend to claims which the creditor does
            not know or suspect to exist in his favor at the time of executing
            the release, which if known by him must have materially affected his
            settlement with the debtor.” If you are a resident of another
            jurisdiction, you waive any comparable statute or doctrine.
          </p>

          <h5>3.12 GOVERNING LAW AND ARBITRATION</h5>
          <p>
            The use of this Product shall be governed by and construed in
            accordance with the laws of United Kingdom without regard to its
            conflict of law provisions. With respect to any disputes or claims
            not subject to arbitration, as set forth below, you and Company
            agree to submit to the personal and exclusive jurisdiction of the
            courts located within United Kingdom.
          </p>
          <p>
            This Governing Law and Arbitration section is referred to in this
            Terms of Service as the “Arbitration Agreement.” You agree that any
            and all disputes, differences and/or claims arising out of or in
            connection with this Agreement shall be resolved exclusively through
            final and binding arbitration rather than a court, in accordance
            with the terms of this Arbitration Agreement, except that you may
            assert individual claims in small claims court, if your claims
            qualify. Further, this Arbitration Agreement does not preclude you
            from bringing issues to the attention of federal, state, or local
            agencies, and such agencies can, if the law allows, seek relief
            against us on your behalf. You agree that, by entering into this
            Terms of Service, you and Company are each waiving the right to a
            trial by jury or to participate in a class action. Your rights will
            be determined by a neutral arbitrator, not a judge or jury.
          </p>
          <p>
            To the extent permissible by law, the determination of the
            arbitrator shall be final, conclusive and binding upon the parties
            hereto.
          </p>
          <p>
            Notwithstanding any of the provisions of this clause, the parties
            reserve the right to appeal on points of law. Prohibition of Class
            and Representative Actions and Non-Individualized Relief You and
            company agree that each of us may bring claims against the other
            only on an individual basis and not as a plaintiff or class member
            in any purported class or representative action or proceeding.
            Unless both you and company agree otherwise, the arbitrator may not
            consolidate or join more than one person's or party's claims and may
            not otherwise preside over any form of a consolidated,
            representative, or class proceeding. Also, the arbitrator may award
            relief (including monetary, injunctive, and declaratory relief) only
            in favor of the individual party seeking relief and only to the
            extent necessary to provide relief necessitated by that party's
            individual claim(s).
          </p>
          <p>Pre-Arbitration Dispute Resolution</p>
          <p>
            Company is always interested in resolving disputes amicably and
            efficiently, and most customer concerns can be resolved quickly and
            to the customer's satisfaction by emailing customer support at
            support@changa.co.ke. If such efforts prove unsuccessful, a party
            who intends to seek arbitration must first send to the other, by
            certified mail, a written Notice of Dispute (“Notice”). The Notice
            to Company should be sent to Westcom Building, Mahiga Mairu Avenue,
            off Waiyaki Way, 3rd Floor, Block B, Nairobi, KENYA P.O. Box
            55237-00200 (“Notice Address”). The Notice must (i) describe the
            nature and basis of the claim or dispute and (ii) set forth the
            specific relief sought. If Company and you do not resolve the claim
            within sixty (60) calendar days after the Notice is received, you or
            Company may commence an arbitration proceeding. During the
            arbitration, the amount of any settlement offer made by Company or
            you shall not be disclosed to the arbitrator until after the
            arbitrator determines the amount, if any, to which you or Company is
            entitled.
          </p>
          <p>Arbitration Procedures</p>
          <p>
            Arbitration will be conducted by a neutral (single) arbitrator in
            accordance with the American Arbitration Association's (“AAA”) rules
            and procedures, including AAA's Supplementary Procedures for
            ConsumerRelated Disputes (collectively, the “AAA Rules”), as
            modified by this Arbitration Agreement. For information on the AAA,
            please visit its website, http://www.adr.org. Information about the
            AAA Rules and fees for consumer disputes can be found at the AAA's
            consumer arbitration page, www.adr.org/consumer_arbitration. If
            there is any inconsistency between any term of the AAA Rules and any
            term of this Arbitration Agreement, the applicable terms of this
            Arbitration Agreement will control unless the arbitrator determines
            that the application of the inconsistent Arbitration Agreement terms
            would not result in a fundamentally fair arbitration. The arbitrator
            must also follow the provisions of these Terms of Service as a court
            would. All issues are for the arbitrator to decide, including, but
            not limited to, issues relating to the scope, enforceability, and
            arbitrability of this Arbitration Agreement. Although arbitration
            proceedings are usually simpler and more streamlined than trials and
            other judicial proceedings, the arbitrator can award the same
            damages and relief on an individual basis that a court can award to
            an individual under the Terms of Service and applicable law.
            Decisions by the arbitrator are enforceable in court and may be
            overturned by a court only for very limited reasons.
          </p>
          <p>
            Unless Company and you agree otherwise, any arbitration hearings
            will take place in a reasonably convenient location for both parties
            with due consideration of their ability to travel and other
            pertinent circumstances. If the parties are unable to agree on a
            location, the determination shall be made by AAA. If your claim is
            for $10,000 or less, Company agrees that you may choose whether the
            arbitration will be conducted solely on the basis of documents
            submitted to the arbitrator, through a telephonic hearing, or by an
            in-person hearing as established by the AAA Rules. If your claim
            exceeds $10,000, the right to a hearing will be determined by the
            AAA Rules. Regardless of the manner in which the arbitration is
            conducted, the arbitrator shall issue a reasoned written decision
            sufficient to explain the essential findings and conclusions on
            which the award is based.
          </p>
          <p>Costs of Arbitration</p>
          <p>
            Payment of all filing, administration, and arbitrator fees
            (collectively, the “Arbitration Fees”) will be governed by the AAA
            Rules, unless otherwise provided in this Arbitration Agreement. If
            the value of the relief sought is $75,000 or less, at your request,
            Company will pay all Arbitration Fees. If the value of relief sought
            is more than $75,000 and you are able to demonstrate to the
            arbitrator that you are economically unable to pay your portion of
            the Arbitration Fees or if the arbitrator otherwise determines for
            any reason that you should not be required to pay your portion of
            the Arbitration Fees, Company will pay your portion of such fees. In
            addition, if you demonstrate to the arbitrator that the costs of
            arbitration will be prohibitive as compared to the costs of
            litigation, Company will pay as much of the Arbitration Fees as the
            arbitrator deems necessary to prevent the arbitration from being
            cost-prohibitive. Any payment of attorneys' fees will be governed by
            the AAA Rules.
          </p>
          <p>Confidentiality</p>
          <p>
            All aspects of the arbitration proceeding, and any ruling, decision,
            or award by the arbitrator, will be strictly confidential for the
            benefit of all parties. Severability
          </p>
          <p>
            If a court or the arbitrator decides that any term or provision of
            this Arbitration Agreement (other than the subsection (b) titled
            “Prohibition of Class and Representative Actions and
            Non-Individualized Relief” above) is invalid or unenforceable, the
            parties agree to replace such term or provision with a term or
            provision that is valid and enforceable and that comes closest to
            expressing the intention of the invalid or unenforceable term or
            provision, and this Arbitration Agreement shall be enforceable as so
            modified. If a court or the arbitrator decides that any of the
            provisions of subsection (b) above titled “Prohibition of Class and
            Representative Actions and Non-Individualized Relief” are invalid or
            unenforceable, then the entirety of this Arbitration Agreement shall
            be null and void. The remainder of the Terms of Service will
            continue to apply.
          </p>
          <p>Future Changes to Arbitration Agreement</p>
          <p>
            Notwithstanding any provision in this Terms of Service to the
            contrary, Company agrees that if it makes any future change to this
            Arbitration Agreement (other than a change to the Notice Address)
            while you are a user of the Services, you may reject any such change
            by sending Company written notice within thirty (30) calendar days
            of the change to the Notice Address provided above. By rejecting any
            future change, you are agreeing that you will arbitrate any dispute
            between us in accordance with the language of this Arbitration
            Agreement as of the date you first accepted these Terms of Service
            (or accepted any subsequent changes to these Terms of Service).
          </p>
          <h5>3.13 SEVERABILITY</h5>
          <p>
            Whenever possible, each provision of this Agreement, will be
            interpreted in such manner as to be effective and valid under the
            applicable requirements of the governing law (clause 4.10), but if
            any provision of this Agreement is held by any court of competent
            jurisdiction to be invalid, illegal, or unenforceable in any respect
            under the applicable law or rule, such invalidity, illegality, or
            unenforceability will not affect any other provision, but this
            Agreement will be reformed, construed, and enforced as if such
            invalid, illegal, or unenforceable provision(s) had never been
            contained herein.
          </p>
          <h5>3.14 PRIVACY</h5>
          <p>
            At the Company, we respect the privacy of our users. For details
            please see our Privacy Policy. By using the Service, you consent to
            our collection and use of personal data as outlined therein.
          </p>
          <h5>3.15 GENERAL</h5>
          <p>
            These Terms of Service constitute the entire agreement between you
            and Company and govern your use of the Service, superseding any
            prior agreements between you and Company with respect to the
            Service. You also may be subject to additional terms and conditions
            that may apply when you use affiliate or third party services, third
            party content or third party software. You agree that regardless of
            any statute or law to the contrary, any claim or cause of action
            arising out of or related to use of the Service or these Terms of
            Service must be filed within one (1) year after such claim or cause
            of action arose or be forever barred. A printed version of this
            agreement and of any notice given in electronic form will be
            admissible in judicial or administrative proceedings based upon or
            relating to this agreement to the same extent and subject to the
            same conditions as other business documents and records originally
            generated and maintained in printed form. You may not assign this
            Terms of Service without the prior written consent of Company, but
            Company may assign or transfer this Terms of Service, in whole or in
            part, without restriction. The section titles in these Terms of
            Service are for convenience only and have no legal or contractual
            effect. Notices to you may be made via either email or regular mail.
            The Service may also provide notices to you of changes to these
            Terms of Service or other matters by displaying notices or links to
            notices generally on the Service.
          </p>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import Footer from "../components/Footer/index.vue";
export default {
  components: {
    Breadcrumbs,
    Footer,
  },
  data() {
    return {};
  },
};
</script>
